import React from "react";
import "../styles/App.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Button } from "../components/Button";
import opImg from "../images/op-1000.jpeg";

export default function OverlandPark() {
  return (
    <>
      <NavBar
        title="Overland Park Movers | Point Blank Moving"
        description="Overland Park Movers, the best local moving company in Overland Park, Kansas. Point Blank Moving is Kansas City's premier moving company! Proud to be servicing Overland Park Kansas. The best locally operated moving company out of Overlandpark."
      />
      <div
        className="landing-container"
        style={{
          backgroundImage: `url(${opImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <h1 style={{ textShadow: "0 0 5px black, 0 0 5px black" }}>
          Overland Park Movers
        </h1>
        <div className="landing-btns">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            path="/Quote"
          >
            Check Availability
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
            path="/Contact"
          >
            GET IN CONTACT
          </Button>
        </div>
      </div>
      <div>
        <div className="content">
          <div className="content--loc--header">
            <h1>Overland Park Movers</h1>
            <div>
              Top rated Overland Park moving company,{" "}
              <strong>point.blank moving</strong> is the mover you can trust.
              Local & long-distance moving services with amazing rates. Whether
              you are leaving your current apartment for an upgraded larger
              apartment or home across town, or relocating to the other side of
              Kansas City, the State or the US – Point.Blank Moving is the
              company that will ensure you have a stress-free move.
            </div>
          </div>
          <div className="content--loc--generic">
            <h1>Professional Overland Park Movers</h1>
            <div>
              Looking for a no-surprises moving quote and professional movers
              that service the Overland Park and Great Kansas City area? Then{" "}
              <strong>point.blank moving</strong> is the moving company you’ve
              been searching for. We are committed to creating happy and
              efficient moves (bet you didn’t think those existed) all across
              Kansas City.
            </div>
          </div>
          <div className="content--loc--generic">
            <h1>Your Trusted Local Movers</h1>
            <div>
              <strong>Point.blank Moving</strong> is a full-service mover with
              our corporate office located in Overland Park. Check out our
              amazing 5 star reviews, and you will see how we treat all of our
              customers, including you. Our goal is to make sure every customer
              is given 5 Star service so we can earn a 5 Star rating from you!
            </div>
          </div>
          <div className="content--loc--generic">
            <h1>Straight Forward Pricing without Hidden Fees</h1>
            <div>
              We make it straight forward with honest pricing and stand behind
              our word and service. As a locally owned company, that lives here
              in the community, we want to make sure when you need a{" "}
              <strong>moving quote</strong> that what you are quoted, is the
              price that you pay.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
